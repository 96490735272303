import React from "react";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import { Link } from "react-router-dom";

export default function () {
  return (
    <div className="footer">
      <div className="container">
        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
          <div className="footer-item">
            <p className="footer-item-text" style={{marginTop: 4}}>FESPA 2024 ©</p>
          </div>
          {/* <div className="footer-item">
            <p className="footer-item-text">Cookie Policy</p>
          </div> */}
          <div className="footer-item">
            {/* <PrivacyPolicyModal
              openModal={<p className="footer-item-text">Privacy Policy</p>}
            /> */}
            <Link to="/PrivacyPolicy"><p className="footer-item-text">Privacy Policy</p></Link>
          </div>
        </div>
      </div>
    </div>
  );
}
