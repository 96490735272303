import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hero from './layout/Hero';
import Description from './layout/Description';
import PictureCards from './layout/PictureCards';
import Bonus from './layout/Bonus';
import Types from './layout/Types';
import Faq from './layout/Faq';
import FormSection from './layout/FormSection';
import Footer from './components/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
 
function LandingPage() {
  return (
    <div className="App">
      <Hero />
      <Description />
      <PictureCards />
      <Bonus />
      <Types />
      <Faq />
      <FormSection />
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;