import React, { useEffect, useState } from "react";
import axios from "axios";

export default function PrivacyPolicy() {
  const [legacy, setLegacy] = useState("");

  useEffect(() => {
    axios
      .get("https://api-v2.metodofespa-app.com/api/legacy/all")
      .then((response) => setLegacy(response.data.list))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="privacy-wrapper">
      {legacy.length > 0 &&
        legacy.map((item, index) => (
          <div
            key={index}
            dangerouslySetInnerHTML={{
              __html: item.category === "Privacy Policy" ? item.content : null,
            }}
          />
        ))}
    </div>
  );
}
